var postcodeResults = [];
var productIndex = 0;
var _3ds_checker = null;
var _3ds_ajax = null;
var basket_id = 0;

registry = {
    registerurl: '/process-registration'
}

var total_testimonials = 0;
var current_testimonial = 0;


function format_money(price)
{
    price = parseFloat(price);
    return price.toFixed(2).replace(/./g, function (c, i, a) {
        return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
    });
}

function calculateTotal()
{
    var total = 0;
    $.each($('.basket-toadd'), function (key, val) {
        total += parseFloat($(this).attr('data-price'));
    });

    $('#total').html('' + format_money(total));
}

function addProduct(id, name, price)
{

    price = format_money(price);
    var html = '<input class="basket-toadd" data-price="' + price + '" type="hidden" value="' + id + '" name="products[' + (++productIndex) + ']">';
    $('#productForm').append(html);

    $('.pricing tbody').append(('<tr id="prod-' + id + '"><td>' + name + '<br><span>&pound;' + price + '</span>'));
    calculateTotal();
    return productIndex;
}

function removeProduct(id)
{
    $('input[value="' + id + '"]').remove();
    calculateTotal();
}

function updateIndice(attr, index)
{
    attr.attr('data-selected', index);
}

$(document).ready(function ()
{

    total_testimonials = $('.testimonial-wrapper').length;
    $('#testimonial-0').show();

    setInterval(function() {
            current_testimonial = ((current_testimonial+ 1) % total_testimonials);
            $('.testimonial-wrapper').hide();

            $('#testimonial-'+current_testimonial).show();
    }, 8000);

    $('.addon-checkbox').on('ifChecked', function () { //Do your code })
        var parent = $(this).closest('.productpluginbox');
        var price = parent.data('price');
        var id = parent.data('id');
        var name = parent.data('name');
        var index = addProduct(id, name, price);
        updateIndice($(this), index);
    });

    $('.addon-checkbox').on('ifUnchecked', function () {
        var parent = $(this).closest('.productpluginbox');
        $('#prod-' + parent.data('id')).remove();

        removeProduct(parent.data('id'));
    });


    $(".getthebestbox.tab1").click(function () {
        $(".getthebestbox").removeClass("active");
        $(".getthebestbox.tab1").addClass("active");
        $(".prodvideo .tabbox").removeClass("active");
        $(".prodvideo .tabbox.tabbox1").addClass("active");
    });
    $(".getthebestbox.tab2").click(function () {
        $(".getthebestbox").removeClass("active");
        $(".getthebestbox.tab2").addClass("active");
        $(".prodvideo .tabbox").removeClass("active");
        $(".prodvideo .tabbox.tabbox2").addClass("active");
    });
    $(".getthebestbox.tab3").click(function () {
        $(".getthebestbox").removeClass("active");
        $(".getthebestbox.tab3").addClass("active");
        $(".prodvideo .tabbox").removeClass("active");
        $(".prodvideo .tabbox.tabbox3").addClass("active");
    });


    $('.error-feedback input').focus(function (e) {
        $(this).parent().removeClass('error-feedback');
    });

    $('.postcode-find').click(function (e) {
        var search = $('input[name="postcode"]');
        if (search.val().length < 4) {
            search.addClass('feedback-error');
            return;
        }
        search.removeClass('feedback-error');
        $(this).hide();


        $('.magnifier').fadeIn(100);
        $('.select-postcode').hide();

        $('input[name="postcode"]').animate({'opacity': 0.5}, 100);
        $('input[name="postcode"]').prop('disabled', true);

        $.ajax('/shop/lookup-postcode/' + $('input[name="postcode"]').val()).done(function (result) {
            $('.select-postcode').show();
            $('.magnifier').hide();
            $('input[name="postcode"]').animate({'opacity': 1}, 100).prop('disabled', false);
            $('.postcode-find').fadeIn(500);
            postcodeResults = result;
            $('.postcode-select').html();
            var newHtml = '';

            for (var x in result) {
                newHtml += '<option value="' + x + '">' + result[x][0] + ' ' + result[x][3] + '</option>';
            }
            $('.postcode-select').html(newHtml);

        });


    });

    $('.postcode-select').change(function (e) {
        var value = $(this).val();
        var result = postcodeResults[parseInt(value)];
        $('input[name="address1"]').val(result[0]);
        $('input[name="address2"]').val(result[1]);
        $('input[name="town"]').val(result[3]);
        $('.select-postcode').hide();
    });

    $('.checkout-card').on('keydown', function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        } else {
            if ($(this).val().length >= 4)
                e.preventDefault();
        }
    });
    $('.checkout-card').keyup(function (e) {
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        if ($(this).val().length == 4) {
            var name = $(this).attr('name');
            var substr = parseInt(name.substring(4)) + 1;

            $('input[name="card' + substr + '"]').focus();

        }
    });
});


// Page functions
function setPageTitle(title)
{
    $('.wrapper').find('h1').first().html(title);
}

function setCardError(msg)
{
    $('.card-errormsg').html(msg);
    $('.card-error').show();
}

// 3DS Functions

function stop3DS()
{
    if (_3ds_checker != null) {
        clearInterval(_3ds_checker);
        _3ds_checker = null;
    }
}

function handle3DSResult(result)
{
    if (result.success == true) {
        switch (result.status) {
            case 'success':
                window.location = '/shop/thankyou';
                stop3DS();
                break;
            case 'error':
                stop3DS();
                setCardError('3D Secure failed please try again or call us on 01642 378 201')
                setPageTitle('Payment Summary');
                $('#_3dframe').hide();
                $('#3dForm').show();
                break;
        }
    } else {
        alert("Something went wrong - please refresh the page.")
    }
}

function check3DS()
{
    if (_3ds_ajax != null) {
        switch (_3ds_ajax.status) {
            case 200:
            case 500:
                break;
            default:
                return;
                break;
        }
    }

    _3ds_ajax = $.ajax('/3ds/status/'+basket_id).done(function (result) {
        handle3DSResult(result);
        _3ds_ajax = null;
    });
}

function begin3DS()
{
    _3ds_checker = setInterval(function () {
        check3DS();
    }, 1000);
}

function handlePaymentResponse(response, target)
{

    $('.card-error').css('display', 'block');
    $('.card-error').hide();
    $("html, body").animate({scrollTop: 0}, "slow");

    switch (response.action) {

        case 'payment_complete':
            if(target != undefined)
                window.location = window.location.origin + target;
            else
                window.location = window.location.origin + '/shop/thankyou';
            return;
        break;

        case 'failed':

            setCardError(response.failed_msg);
            $('.refno').html(response.refno);
            return;


        case '3ds_secure':
            $('#payment').hide();
            $('#3ds-redirect').show();
            var form = $('#3dForm');
            var frame = $('#_3dframe');
            basket_id = response.id;
            setPageTitle('Verify With Bank')
            frame.attr('src', window.location.origin + '/3ds/frame/' + response._3ds_hash);
            begin3DS();
            break;
    }
}


function pad(pad, str, padLeft)
{
    if (typeof str === 'undefined')
        return pad;
    if (padLeft) {
        return (pad + str).slice(-pad.length);
    } else {
        return (str + pad).substring(0, pad.length);
    }
}


jQuery(document).ready(function( $ )
{
    $('.counterup').counterUp({
        delay: 10,
        time: 1000
    });
    var cache = [];
    for(var i = 0; i < 6; i++) {
        cache[i] = $('.number'+i);
    }
    $('.incrementer').counterUp({delay: 10, time: 1000, callback: function(result){
        var padded = pad('000000', result, true);
        var explode = padded.split('');


        var ind = 0;
        for(var i in explode){
            cache[ind++].html(explode[i]);
        }
    }});


});



